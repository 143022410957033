import { Question } from '../models/question';
/**
 * Bot service
 *
 * Overview: Provides Bot related services
 *
 */

export class BotService {

    /**
     * Calls bot API to ask question.
     * @param botKnowledgeBaseId API knowledgeBase Id
     * @param botAuthorizationEndpointKey API authorization key
     * @param question Model housing bot question API model
     * @returns Status 200 if returned
     */
    //@ts-ignore
    askQuestion(endpointRoot: string, botKnowledgeBaseId: string, botAuthorizationEndpointKey: string, question: Question) {
        return new Promise((resolve, reject) => {
            const headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'EndpointKey 69ccf40a-ac15-4259-841d-97e4ad9f382d'
            };
            fetch('https://hak-qna-maker.azurewebsites.net/qnamaker/knowledgebases/e4b51411-0176-446f-914c-6171dfb79f23/generateAnswer', {
                method: 'POST',
                body: JSON.stringify(question),
                headers,
                mode: 'cors'
            }).then((response) => {
                if (response.ok) {
                    return resolve(response);
                } else {
                    return reject(response);
                }
            });
        });
    }
}
